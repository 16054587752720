import * as React from "react"
import styled from "styled-components"
import "@fontsource/poiret-one"

const Header = ({ siteTitle }) => (
  <Main>
    <MainTitle>
      <Text>Pilot Bikes</Text>
    </MainTitle>
  </Main>
)

export default Header

const Main = styled.div`
  background: transparent;
  height: 180px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  position: relative;
`
const MainTitle = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Text = styled.h1`
  font-size: clamp(2.5rem, 7vw, 5rem);

  font-family: "Poiret One", cursive;
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  padding: 0 1rem;
  color: #fff;
`
